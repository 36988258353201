.bg-hero {
  width: 100%;
  height: 100%;
  background-image: url("../../public/assets/heroBg.svg");
  /* background-attachment: fixed; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.bg-hero2 {
  background-image: url("../../public/assets/Hero-bg2.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text-large-display {
  font-size: clamp(36px, 6vw, 64px);
  line-height: 1.3;
}

.lg-display-text {
  font-size: clamp(36px, 6vw, 60px);
  line-height: 1.3;
}

.text-paragraph {
  font-size: clamp(20px, 2.5vw, 22px);
  line-height: 1.4;
}

.title-text {
  font-size: clamp(30px, 3.5vw, 48px);
  line-height: 1.4;
}

.medium-title-text {
  font-size: clamp(20px, 2.5vw, 24px);
  line-height: 1.4;
}

.text-paragraph-sm {
  font-size: clamp(18px, 2.5vw, 20px);
  line-height: 1.4;
}

.text-paragraph-xl {
  font-size: clamp(24px, 2.5vw, 36px);
  line-height: 1.4;
}

.inline-video {
  width: 90%;
  height: auto;
  background-size: cover;
  max-width: 640px;
  max-height: 360px;
}

.embed-container {
  height: 0;
  width: 100%;
  height: 100%;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #4962b3;
  font-size: 12px;
  border: 1px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.custom-shape-divider-bottom-arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-arrow svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-arrow .shape-fill {
  fill: #FFFFFF;
}

.custom-shape-divider-bottom {
  margin: 0px;
  padding: 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  fill: white;
}

.custom-shape-divider-top-cost {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-cost svg {
  position: relative;
  display: block;
  width: calc(145% + 1.3px);
  height: 70px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-cost .shape-fill {
  fill: #FFFFFF;
}

.custom-shape-divider-bottom-cost {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-cost svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 70px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-cost .shape-fill {
  fill: #FFFFFF;
}

.custom-shape-divider-top-cta {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-cta svg {
  position: relative;
  display: block;
  width: calc(145% + 1.3px);
  height: 70px;
}

.custom-shape-divider-top-cta .shape-fill {
  fill: #FFFFFF;
}

.custom-shape-divider-bottom-cta {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-cta svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 56px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-cta .shape-fill {
  fill:  #262548;
}

.custom-shape-divider-bottom-about-header {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-about-header svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 110px;
}

.custom-shape-divider-bottom-about-header .shape-fill {
  fill: #FFFFFF;
}