* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  width: 100%;
  height: 100vh;
}
